import dynamic from 'next/dynamic';
import axios from 'axios';
import { useState, useRef, Fragment } from 'react';
import { useLocationState } from '../../context/location';
import Input from '../Input';
import Textarea from '../Textarea';
import styles from '../../styles/modules/home/HomeBanner.module.scss';

const Modal = dynamic(() => import('../Modal'));

const HomeBanner = ({ title, text }) => {
  const locationState = useLocationState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientComment, setClientComment] = useState('');
  const clientNameId = useRef();
  const clientPhoneId = useRef();

  const handleFormSubmit = () => {
    clientNameId.current.setError(clientName.length <= 3);
    clientPhoneId.current.setError(clientPhone.length !== 11);

    if (clientName.length > 3 && clientPhone.length === 11) {
      setIsSubmited(true);

      axios.post(process.env.NEXT_PUBLIC_API + '/client', {
        clientName,
        clientPhone: clientPhone,
        clientComment,
        pathName: '/',
        whatComponent: 'HomeBanner',
        location: locationState.name,
        platform: 'web'
      });
      ym(21340345, 'reachGoal', 'all_form_submit');
    }
  };

  return (
    <section className="block">
      <Modal
        title="Обратная связь"
        button={!isSubmited && 'Отправить'}
        action={handleFormSubmit}
        visibility={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        {isSubmited ? (
          <Fragment>
            <div style={{ marginBottom: 18 }}>
              Спасибо за вашу заявку. Мы скоро с Вами свяжемся!
            </div>
            <img
              src="/images/thumbUp.png"
              alt=""
              style={{ padding: 50, width: '100%', boxSizing: 'border-box' }}
            />
          </Fragment>
        ) : (
          <Fragment>
            <div style={{ marginBottom: 18 }}>
              Оставьте свои контактные данные и мы с Вами свяжемся в самые кратчайшие сроки, поможем
              решить все имеющиеся у Вас вопросы!
            </div>
            <Input
              title="Имя"
              inputs={[
                {
                  placeholder: 'Василий Петрович',
                  value: clientName,
                  type: 'text'
                }
              ]}
              isWrappedStyle
              ref={clientNameId}
              onChange={(v) => setClientName(v[0])}
            />
            <Input
              title="Телефон"
              inputs={[
                {
                  placeholder: locationState.phone,
                  value: clientPhone,
                  mask: 'phone',
                  type: 'tel'
                }
              ]}
              isWrappedStyle
              ref={clientPhoneId}
              onChange={(v) => setClientPhone(v[0])}
            />
            <Textarea
              title="Коммментарий"
              placeholder="Например, «Хочу получить консультацию перед продажей своей квартиры в городе»"
              autoHeight
              isWrappedStyle
              onChange={(v) => setClientComment(v)}
            />
            <div style={{ textAlign: 'center', opacity: 0.8, fontSize: 14, margin: '18px 0' }}>
              Вы соглашаетесь с{' '}
              <a href="/downloads/personal.pdf" target="_blank">
                политикой конфиденциальности
              </a>{' '}
              при отправке данной формы к нам в работу
            </div>
          </Fragment>
        )}
      </Modal>
      <div className="fixed fill">
        <div className={styles.banner}>
          {title.map((v, i) => (
            <h2 className={styles.bannerTitle} key={i}>
              {v}
            </h2>
          ))}
          {text.map((v, i) => (
            <div className={styles.bannerSubtitle} key={i}>
              {v}
            </div>
          ))}
          <button
            className={styles.bannerButton}
            onClick={() => setIsModalVisible(true)}
            type="button"
          >
            Написать нам
          </button>
          <div className={styles.bannerIllustration}>
            <img src="/images/modul.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
