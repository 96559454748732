import { useState, useEffect, Fragment } from 'react';
import styles from '../../styles/modules/home/HomeSearchCheckbox.module.scss';

const HomeSearchCheckbox = ({ checkboxes, onChange }) => {
  const [value, setValue] = useState([]);

  const handleCheckboxClick = (v) => {
    setValue(value.includes(v) ? value.filter((item) => item !== v) : value.concat([v]));
  };

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  return (
    <div className={styles.checkbox}>
      {checkboxes.map((v, i) => (
        <Fragment key={i}>
          <button
            type="button"
            className={[styles.checkboxButton, value.includes(v.value) && styles.active]
              .filter(Boolean)
              .join(' ')}
            onClick={() => handleCheckboxClick(v.value)}
          >
            {v.text}
          </button>
          {i < Object.keys(checkboxes).length - 1 && <div className={styles.checkboxSplitter} />}
        </Fragment>
      ))}
    </div>
  );
};

export default HomeSearchCheckbox;
