import { useState, useEffect, Fragment } from 'react';
import { NumericFormat } from 'react-number-format';
import styles from '../../styles/modules/home/HomeSearchInput.module.scss';

const HomeSearchInput = ({ inputs, onChange }) => {
  const [value, setValue] = useState(['', '']);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  return (
    <div className={styles.input}>
      {inputs.map((v, i) => (
        <Fragment key={i}>
          <NumericFormat
            thousandSeparator=" "
            placeholder={v.placeholder}
            allowNegative={false}
            suffix={v.suffix}
            prefix={v.prefix}
            isAllowed={({ formattedValue, floatValue }) =>
              formattedValue === '' || floatValue <= (v.max || 9999999999)
            }
            onValueChange={(x) => {
              const newValue = [...value];
              newValue[i] = Number(x.value) ? Number(x.value).toString() : '';

              setValue(newValue);
            }}
          />
          {i < Object.keys(inputs).length - 1 && <div className={styles.inputSplitter} />}
        </Fragment>
      ))}
    </div>
  );
};

export default HomeSearchInput;
