import dynamic from 'next/dynamic';
import { Fragment } from 'react';
import { NextSeo } from 'next-seo';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Splitter from '../components/Splitter';
import HomeWidgetMobile from '../components/home/HomeWidgetMobile';
import HomeCarousel from '../components/home/HomeCarousel';
import HomeBanner from '../components/home/HomeBanner';
import HomeTools from '../components/home/HomeTools';
import HomeWidget from '../components/home/HomeWidget';
import HomeFeatures from '../components/home/HomeFeatures';

const HomeSEO = dynamic(() => import('../components/home/HomeSEO'));

const HomePage = () => {
  return (
    <Fragment>
      <NextSeo
        title="Агентство недвижимости в Ростове-на-Дону, Азове, Новочеркасске и Батайске «ЛЕНДЛОРД»"
        description="Продажа недвижимости в Ростовской области: Ростове-на-Дону, Азове, Новочеркасске, Батайске. Объявления, поиск, цены, купить недвижимость — сайт агентства «ЛЕНДЛОРД»."
        openGraph={{
          url: 'https://landlord.ru',
          type: 'website',
          title:
            'Агентство недвижимости в Ростове-на-Дону, Азове, Новочеркасске и Батайске «ЛЕНДЛОРД»',
          description:
            'Продажа недвижимости в Ростовской области: Ростове-на-Дону, Азове, Новочеркасске, Батайске. Объявления, поиск, цены, купить недвижимость — сайт агентства «ЛЕНДЛОРД».',
          images: [
            {
              url: '/images/og-preview.png'
            }
          ],
          site_name: 'LANDLORD'
        }}
      />
      <Header />
      <main>
        <HomeWidget />
        <HomeWidgetMobile />
        <HomeFeatures />
        <HomeCarousel />
        <HomeBanner
          title={['Требуется помощь?', 'Доверьте нам выбор!']}
          text={[
            'Наши опытные специалисты свяжутся с вами, чтобы индивидуально подобрать объекты по вашим потребностям'
          ]}
        />
        <HomeTools />
        <Splitter
          title="Помочь c продажей?"
          subtitle="Оставьте свой номер телефона и мы обязательно поможем с продажей"
        />
        <HomeSEO />
      </main>
      <Footer seo="Купить недвижимость в Ростове-на-Дону, Азове, Новочеркасске и Батайске" />
    </Fragment>
  );
};

export default HomePage;
