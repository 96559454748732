import Link from 'next/link';
import Image from 'next/image';
import { useObjectsDispatch } from '../../context/objects';
import styles from '../../styles/modules/home/HomeTools.module.scss';

const HomeTools = () => {
  const setObjectsState = useObjectsDispatch();

  return (
    <section className="block">
      <div className="fixed">
        <h3>Быстрые ссылки</h3>
        <div className={styles.tools}>
          <div className={styles.toolsItem}>
            <div className={styles.toolsItemTitle}>Квартиры</div>
            <Link
              href="/objects/buy/flats/studio"
              passHref
              role="button"
              tabIndex={0}
              onClick={() => {
                setObjectsState({
                  type: 'SET_FILTER',
                  payload: {
                    type: 'продажа',
                    category: 'квартира',
                    studio: 1
                  }
                });
              }}
            >
              Студии
            </Link>
            <Link
              href="/objects/buy/flats/1k"
              passHref
              role="button"
              tabIndex={0}
              onClick={() => {
                setObjectsState({
                  type: 'SET_FILTER',
                  payload: {
                    type: 'продажа',
                    category: 'квартира',
                    rooms: ['1']
                  }
                });
              }}
            >
              Однокомнатные
            </Link>
            <Link
              href="/objects/buy/flats/2k"
              passHref
              role="button"
              tabIndex={0}
              onClick={() => {
                setObjectsState({
                  type: 'SET_FILTER',
                  payload: {
                    type: 'продажа',
                    category: 'квартира',
                    rooms: ['2']
                  }
                });
              }}
            >
              Двухкомнатные
            </Link>
            <Link
              href="/objects/buy/flats/3k"
              passHref
              role="button"
              tabIndex={0}
              onClick={() => {
                setObjectsState({
                  type: 'SET_FILTER',
                  payload: {
                    type: 'продажа',
                    category: 'квартира',
                    rooms: ['3']
                  }
                });
              }}
            >
              Трехкомнатные
            </Link>
          </div>
          <div className={styles.toolsItem}>
            <div className={styles.toolsItemTitle}>Недвижимость</div>
            <Link
              href="/objects/buy/houses"
              passHref
              role="button"
              tabIndex={0}
              onClick={() => {
                setObjectsState({
                  type: 'SET_FILTER',
                  payload: {
                    type: 'продажа',
                    category: 'дом'
                  }
                });
              }}
            >
              Дом
            </Link>
            <Link
              href="/objects/buy/sites"
              passHref
              role="button"
              tabIndex={0}
              onClick={() => {
                setObjectsState({
                  type: 'SET_FILTER',
                  payload: {
                    type: 'продажа',
                    category: 'участок'
                  }
                });
              }}
            >
              Участок
            </Link>
            <Link
              href="/objects/buy/commercial"
              passHref
              role="button"
              tabIndex={0}
              onClick={() => {
                setObjectsState({
                  type: 'SET_FILTER',
                  payload: {
                    type: 'продажа',
                    category: 'коммерция'
                  }
                });
              }}
            >
              Коммерческая
            </Link>
          </div>
          <div className={styles.toolsItem}>
            <img src="/images/mappoint.webp" alt="" />
            <div className={styles.toolsItemTitle}>Поиск на карте</div>
            <div className={styles.toolsItemSubtitle}>
              Ищите жилье рядом с парком,
              <br />
              родственниками или за городом
            </div>
            <Link href="/objects/map" passHref className={styles.toolsItemButton} type="button">
              Подробнее на карте
            </Link>
          </div>
          <div className={styles.toolsItem}>
            <img src="/images/superrocket.webp" alt="" />
            <div className={styles.toolsItemTitle}>Объекты для бизнеса</div>
            <div className={styles.toolsItemSubtitle}>
              Список коммерческой недвижимости
              <br />
              уже ждет Вас в нашем разделе
            </div>
            <Link
              href="/objects/buy/commercial"
              passHref
              className={styles.toolsItemButton}
              role="button"
              tabIndex={0}
              onClick={() => {
                setObjectsState({
                  type: 'SET_FILTER',
                  payload: {
                    type: 'продажа',
                    category: 'коммерция'
                  }
                });
              }}
            >
              Открыть каталог
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeTools;
