import dynamic from 'next/dynamic';
import axios from 'axios';
import { Fragment, useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useLocationState } from '../../context/location';
import { useObjectsDispatch } from '../../context/objects';
import { declOfNum, JSONToQuery, sleep } from '../../helpers';
import HomeSearchSelect from './HomeSearchSelect';
import HomeSearchCheckbox from './HomeSearchCheckbox';
import HomeSearchInput from './HomeSearchInput';
import Input from '../Input';
import Radio from '../Radio';
import Button from '../Button';
import styles from '../../styles/modules/home/HomeSearch.module.scss';

const Modal = dynamic(() => import('../Modal'));

const HomeSearch = () => {
  const router = useRouter();
  const locationState = useLocationState();
  const setObjectsState = useObjectsDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [foundCount, setFoundCount] = useState(0);
  const [type, setType] = useState('продажа');
  const [category, setCategory] = useState('квартира');
  const [studio, setStudio] = useState(0);
  const [rooms, setRooms] = useState([]);
  const [priceFrom, setPriceFrom] = useState('');
  const [priceTo, setPriceTo] = useState('');
  const [squareFrom, setSquareFrom] = useState('');
  const [squareTo, setSquareTo] = useState('');
  const [squareAreaFrom, setSquareAreaFrom] = useState('');
  const [squareAreaTo, setSquareAreaTo] = useState('');
  const [commercialType, setCommercialType] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [objectType, setObjectType] = useState('продать');
  const [objectCategory, setObjectCategory] = useState('квартира');
  const [objectAddress, setObjectAddress] = useState('');
  const clientNameId = useRef();
  const clientPhoneId = useRef();

  const handleFormSubmit = () => {
    clientNameId.current.setError(clientName.length <= 3);
    clientPhoneId.current.setError(clientPhone.length !== 11);

    if (clientName.length > 3 && clientPhone.length === 11) {
      setIsSubmited(true);

      axios.post(process.env.NEXT_PUBLIC_API + '/client', {
        clientName,
        clientPhone: clientPhone,
        pathName: '/',
        objectFormat: objectType,
        categoryType: objectCategory,
        objectAddress,
        whatComponent: 'HomeSearch',
        location: locationState.name,
        platform: 'web'
      });
      ym(21340345, 'reachGoal', 'all_form_submit');
    }
  };
  const handleSearchClick = () => {
    const newSearch = {};
    let newLink = '/objects';

    switch (type) {
      default:
      case 'продажа':
        newSearch.type = 'продажа';
        newLink += '/buy';
        break;

      case 'аренда':
        newSearch.type = 'аренда';
        newLink += '/rent';
        break;
    }
    switch (category) {
      default:
      case 'квартира':
        newSearch.category = 'квартира';
        newLink += '/flats';
        break;

      case 'комната':
        newSearch.category = 'комната';
        newLink += '/rooms';
        break;

      case 'дом':
        newSearch.category = 'дом';
        newLink += '/houses';
        break;

      case 'участок':
        newSearch.category = 'участок';
        newLink += '/sites';
        break;

      case 'коммерция':
        newSearch.category = 'коммерция';
        newLink += '/commercial';
        break;
    }

    newSearch.priceFrom = priceFrom;
    newSearch.priceTo = priceTo;

    if (category === 'квартира') {
      if (studio && rooms.length === 0) newLink += '/studio';
      else if (!studio && rooms.length === 1) {
        switch (rooms[0]) {
          case '1':
            newLink += '/1k';
            break;

          case '2':
            newLink += '/2k';
            break;

          case '3':
            newLink += '/3k';
            break;

          case '4':
            newLink += '/4k';
            break;

          default:
            break;
        }
      }
    }
    if (category === 'квартира' && (rooms.length || studio)) {
      if (studio) newSearch.studio = 1;
      newSearch.rooms = rooms.filter((it) => it !== 'студия');
    }

    if (category === 'дом' || category === 'комната') {
      newSearch.squareFrom = squareFrom;
      newSearch.squareTo = squareTo;
    }

    if (category === 'коммерция') {
      newSearch.commercialType = commercialType;
    }

    if (category === 'участок') {
      newSearch.squareAreaFrom = squareAreaFrom;
      newSearch.squareAreaTo = squareAreaTo;
    }

    setObjectsState({
      type: 'SET_FILTER',
      payload: newSearch
    });
    setObjectsState({
      type: 'SET_SORTING',
      payload: {}
    });
    setObjectsState({
      type: 'SET_PAGINATION',
      payload: {}
    });
    router.push(
      `${newLink}${JSONToQuery(newSearch, [
        'type',
        'category',
        category === 'квартира' && newSearch.studio && !newSearch.rooms.length && 'studio',
        category === 'квартира' &&
          !newSearch.studio &&
          newSearch.rooms &&
          newSearch.rooms.length === 1 &&
          'rooms'
      ])}`
    );
  };

  useEffect(() => {
    if (!locationState.isLoaded) return;

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      setIsLoading(true);
      await sleep(300);

      try {
        const response = await axios.post(
          process.env.NEXT_PUBLIC_API + '/objects',
          {
            type,
            category,
            rooms: category === 'квартира' ? rooms : [],
            studio: category === 'квартира' ? studio : 0,
            priceFrom,
            priceTo,
            squareFrom: category === 'дом' ? squareFrom : '',
            squareTo: category === 'дом' ? squareTo : '',
            squareAreaFrom: category === 'участок' ? squareAreaFrom : '',
            squareAreaTo: category === 'участок' ? squareAreaTo : '',
            commercialType: category === 'коммерция' ? commercialType : '',
            location: locationState.name,
            stat: 1
          },
          { signal }
        );

        if (!response.data || response.data.status !== 'ok') throw new Error(response.data.text);

        setFoundCount(response.data.data.count);
        setIsLoading(false);
      } catch (error) {}
    };
    fetchData();

    return () => controller.abort();
  }, [
    locationState,
    type,
    category,
    studio,
    rooms,
    priceFrom,
    priceTo,
    squareFrom,
    squareTo,
    squareAreaFrom,
    squareAreaTo,
    commercialType
  ]);

  return (
    <div className={styles.search}>
      <Modal
        title="Предложить объект"
        button={!isSubmited && 'Отправить'}
        action={handleFormSubmit}
        visibility={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        {isSubmited ? (
          <Fragment>
            <div style={{ marginBottom: 18 }}>
              Спасибо за вашу заявку. Мы скоро с Вами свяжемся!
            </div>
            <img
              src="/images/thumbUp.png"
              alt=""
              style={{ padding: 50, width: '100%', boxSizing: 'border-box' }}
            />
          </Fragment>
        ) : (
          <Fragment>
            <div style={{ marginBottom: 18 }}>
              Оставьте свои контактные данные и мы с Вами свяжемся в самые кратчайшие сроки, поможем
              с реализацией Вашего объекта!
            </div>
            <Input
              title="Имя"
              inputs={[
                {
                  placeholder: 'Василий Петрович',
                  value: clientName,
                  type: 'text'
                }
              ]}
              isWrappedStyle
              ref={clientNameId}
              onChange={(v) => setClientName(v[0])}
            />
            <Input
              title="Телефон"
              inputs={[
                {
                  placeholder: locationState.phone,
                  value: clientPhone,
                  mask: 'phone',
                  type: 'tel'
                }
              ]}
              isWrappedStyle
              ref={clientPhoneId}
              onChange={(v) => setClientPhone(v[0])}
            />
            <div style={{ margin: '18px -12px', borderBottom: '1px dashed rgba(0, 0, 0, 0.05)' }} />
            <Radio
              title="Тип сделки"
              radios={[
                { text: 'Продать', value: 'продать' },
                { text: 'Сдать', value: 'сдать', disabled: objectCategory === 'участок' }
              ]}
              selected={objectType}
              onChange={(v) => setObjectType(v)}
            />
            <Radio
              title="Категория объекта"
              radios={[
                { text: 'Квартира', value: 'квартира' },
                { text: 'Комната', value: 'комната' },
                { text: 'Дом', value: 'дом' },
                { text: 'Участок', value: 'участок', disabled: objectType === 'сдать' },
                { text: 'Коммерция', value: 'коммерция' }
              ]}
              selected={objectCategory}
              onChange={(v) => setObjectCategory(v)}
            />
            <Input
              title="Адрес объекта"
              inputs={[
                {
                  placeholder: 'г Ростов-на-Дону, пр-т Соколова, д 80',
                  value: objectAddress,
                  type: 'text'
                }
              ]}
              onChange={(v) => setObjectAddress(v[0])}
            />
            <div style={{ textAlign: 'center', opacity: 0.8, fontSize: 14, margin: '18px 0' }}>
              Вы соглашаетесь с{' '}
              <a href="/downloads/personal.pdf" target="_blank">
                политикой конфиденциальности
              </a>{' '}
              при отправке данной формы к нам в работу
            </div>
          </Fragment>
        )}
      </Modal>
      <div className={styles.searchTabs}>
        <button
          className={[styles.searchTabsButton, type === 'продажа' && styles.active]
            .filter(Boolean)
            .join(' ')}
          onClick={() => setType('продажа')}
          type="button"
        >
          Купить
        </button>
        <div className={styles.searchTabsSplitter} />
        <button
          className={[
            styles.searchTabsButton,
            category === 'участок' && styles.disabled,
            type === 'аренда' && styles.active
          ]
            .filter(Boolean)
            .join(' ')}
          onClick={() => {
            if (category === 'участок') return;

            setType('аренда');
          }}
          type="button"
        >
          Снять
        </button>
        <div className={styles.searchTabsSplitter} />
        <button
          className={styles.searchTabsButton}
          onClick={() => setIsModalVisible(true)}
          type="button"
        >
          Продать
        </button>
        <div className={styles.searchTabsSplitter} />
        <button
          className={styles.searchTabsButton}
          onClick={() => setIsModalVisible(true)}
          type="button"
        >
          Сдать
        </button>
        <div className={styles.searchTabsSplitter} />
        <button
          className={styles.searchTabsButton}
          onClick={() => router.push('/services/assessment')}
          type="button"
        >
          Оценка
        </button>
      </div>
      <div className={styles.searchFilters}>
        <HomeSearchSelect
          options={[
            { text: 'Квартира', value: 'квартира' },
            { text: 'Комната', value: 'комната' },
            { text: 'Дом', value: 'дом' },
            { text: 'Участок', value: 'участок', hidden: type === 'аренда' },
            { text: 'Коммерция', value: 'коммерция' }
          ]}
          selected="квартира"
          onChange={(v) => setCategory(v)}
        />
        {category === 'квартира' && (
          <Fragment>
            <div className={styles.searchFiltersSplitter} />
            <HomeSearchCheckbox
              checkboxes={[
                {
                  text: 'Студия',
                  value: 'студия'
                },
                {
                  text: '1К',
                  value: '1'
                },
                {
                  text: '2К',
                  value: '2'
                },
                {
                  text: '3К',
                  value: '3'
                },
                {
                  text: '4К+',
                  value: '4'
                }
              ]}
              onChange={(v) => {
                if (v.includes('студия')) setStudio(1);
                else setStudio(0);

                setRooms(v.filter((x) => x !== 'студия'));
              }}
            />
          </Fragment>
        )}
        {(category === 'дом' || category === 'комната') && (
          <Fragment>
            <div className={styles.searchFiltersSplitter} />
            <HomeSearchInput
              inputs={[
                {
                  placeholder: 'Площадь от',
                  suffix: ' м²',
                  max: 9999
                },
                {
                  placeholder: 'Площадь до',
                  suffix: ' м²',
                  max: 9999
                }
              ]}
              onChange={(v) => {
                setSquareFrom(v[0]);
                setSquareTo(v[1]);
              }}
            />
          </Fragment>
        )}
        {category === 'участок' && (
          <Fragment>
            <div className={styles.searchFiltersSplitter} />
            <HomeSearchInput
              inputs={[
                {
                  placeholder: 'Площадь от',
                  suffix: ' сот',
                  max: 9999
                },
                {
                  placeholder: 'Площадь до',
                  suffix: ' сот',
                  max: 9999
                }
              ]}
              onChange={(v) => {
                setSquareAreaFrom(v[0]);
                setSquareAreaTo(v[1]);
              }}
            />
          </Fragment>
        )}
        {category === 'коммерция' && (
          <Fragment>
            <div className={styles.searchFiltersSplitter} />
            <HomeSearchSelect
              options={[
                { text: 'Автосервис', value: 'автосервис' },
                { text: 'Гостиница', value: 'гостиница' },
                { text: 'Готовый бизнес', value: 'готовый бизнес' },
                { text: 'Коммерческая земля', value: 'коммерческая земля' },
                { text: 'Общепит', value: 'общепит' },
                { text: 'Офисное помещение', value: 'офисное помещение' },
                { text: 'ПСН', value: 'псн' },
                { text: 'Производственное помещение', value: 'производственное помещение' },
                { text: 'Склад', value: 'склад' },
                { text: 'Торговое помещение', value: 'торговое помещение' }
              ]}
              selected="псн"
              onChange={(v) => setCommercialType(v)}
            />
          </Fragment>
        )}
        <div className={styles.searchFiltersSplitter} />
        <HomeSearchInput
          inputs={[
            {
              placeholder: 'Стоимость от',
              suffix: ' ₽',
              prefix: 'от ',
              max: 999999999
            },
            {
              placeholder: 'Стоимость до',
              suffix: ' ₽',
              prefix: 'до ',
              max: 999999999
            }
          ]}
          onChange={(v) => {
            setPriceFrom(v[0]);
            setPriceTo(v[1]);
          }}
        />
        <Button
          color="green"
          style={{
            margin: '4px 4px 4px auto',
            padding: 10,
            borderRadius: 6,
            width: 120,
            fontWeight: 600
          }}
          loading={isLoading}
          onClick={handleSearchClick}
        >
          {foundCount > 0 ? (
            <div>
              Показать
              <div style={{ fontSize: '0.7rem', fontWeight: 400, opacity: 0.7, marginTop: 2 }}>
                {foundCount} {declOfNum(foundCount, ['объект', 'объекта', 'объектов'])}
              </div>
            </div>
          ) : (
            <div>Найти</div>
          )}
        </Button>
      </div>
    </div>
  );
};

HomeSearch.defaultProps = {
  visibility: true
};

export default HomeSearch;
