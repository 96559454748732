import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import styles from '../styles/modules/Radio.module.scss';

const Radio = forwardRef(({ title, view, radios, selected, visibility, style, onChange }, ref) => {
  const [rd, setRd] = useState(selected);

  useEffect(() => setRd(selected), [selected]);
  useImperativeHandle(ref, () => ({
    get() {
      return rd;
    },
    set(v) {
      if (!radios.filter((r) => r.value.toString() === v.toString()).length) return;

      setRd(v.toString());
    }
  }));

  if (!radios.length || !selected.toString().length || visibility === false) return null;

  return (
    <div
      className={[styles.rd, view !== 'box' && styles[view]].filter(Boolean).join(' ')}
      style={style}
    >
      {title && <div className={styles.rdTitle}>{title}</div>}
      <div className={[styles.rdContent, styles.inline].join(' ')}>
        {radios.map((value, index) => (
          <button
            type="button"
            className={[
              styles.rdRadio,
              rd === value.value && styles.active,
              value.disabled && styles.disabled
            ]
              .filter(Boolean)
              .join(' ')}
            onClick={() => {
              if (rd !== value.value) {
                setRd(value.value);
                if (onChange) onChange(value.value);
              }
            }}
            disabled={value.disabled}
            key={index}
          >
            {value.text}
          </button>
        ))}
      </div>
    </div>
  );
});

Radio.displayName = 'Radio';
Radio.defaultProps = {
  radios: [],
  selected: '',
  visibility: true,
  view: 'box'
};

export default Radio;
