import Skelet from '../Skelet';
import styles from '../../styles/modules/Carousel.module.scss';

const HomeCarouselSkeletonItem = () => {
  return (
    <div className={styles.carouselItem}>
      <Skelet style={{ borderRadius: 10, height: 216, width: 'auto', margin: 2 }} />
      <div className={styles.carouselItemContent}>
        <Skelet className={styles.carouselItemPrice} height={35} width={140} />
        <Skelet
          className={styles.carouselItemAddress}
          height={21}
          width={200}
          style={{ marginTop: 4 }}
        />
        <div className={styles.carouselItemInfo}>
          <Skelet className={styles.carouselItemProperty} />
          <Skelet className={styles.carouselItemProperty} />
          <Skelet className={styles.carouselItemProperty} />
        </div>
      </div>
      <Skelet height={50} style={{ margin: '0 12px 12px 12px', borderRadius: 12, width: 'auto' }} />
    </div>
  );
};

export default HomeCarouselSkeletonItem;
