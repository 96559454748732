import dynamic from 'next/dynamic';
import axios from 'axios';
import { Fragment, useEffect, useRef, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { useLocationState } from '../context/location';
import Input from './Input';
import Textarea from './Textarea';
import styles from '../styles/modules/Splitter.module.scss';

const shakeEl = (el) => {
  el.animate(
    [
      { transform: 'translate3d(-2px, 0, 0)' },
      { transform: 'translate3d(2px, 0, 0)' },
      { transform: 'translate3d(-5px, 0, 0)' },
      { transform: 'translate3d(5px, 0, 0)' },
      { transform: 'translate3d(0, 0, 0)' }
    ],
    200
  );
};

const Modal = dynamic(() => import('./Modal'));

const Splitter = ({ title, subtitle }) => {
  const locationState = useLocationState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientComment, setClientComment] = useState('');
  const clientNameId = useRef();
  const clientPhoneId = useRef();
  const inputParentId = useRef(null);
  const inputId = useRef();

  const handleFeedbackClick = () => {
    if (clientPhone.length === 11) {
      setIsModalVisible(true);
    } else {
      shakeEl(inputParentId.current);
    }
  };
  const handleFormSubmit = () => {
    clientNameId.current.setError(clientName.length <= 3);
    clientPhoneId.current.setError(clientPhone.length !== 11);

    if (clientName.length > 3 && clientPhone.length === 11) {
      setIsSubmited(true);

      axios.post(process.env.NEXT_PUBLIC_API + '/client', {
        clientName,
        clientPhone,
        clientComment,
        pathName: window.location.pathname,
        whatComponent: 'Splitter',
        location: locationState.name
      });
      ym(21340345, 'reachGoal', 'all_form_submit');

      inputId.current.value = '';
    }
  };
  const handleDocumentKey = (e) => {
    if (e.keyCode === 13) {
      handleFeedbackClick();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleDocumentKey);
    return () => document.addEventListener('keyup', handleDocumentKey);
  }, []);

  return (
    <Fragment>
      <Modal
        title="Обратная связь"
        button={!isSubmited && 'Отправить'}
        action={handleFormSubmit}
        visibility={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        {isSubmited ? (
          <Fragment>
            <div style={{ marginBottom: 18 }}>
              Спасибо за вашу заявку. Мы скоро с Вами свяжемся!
            </div>
            <img
              src="/images/thumbUp.png"
              alt=""
              style={{ padding: 50, width: '100%', boxSizing: 'border-box' }}
            />
          </Fragment>
        ) : (
          <Fragment>
            <div style={{ marginBottom: 18 }}>
              Оставьте свои контактные данные и мы с Вами свяжемся в самые кратчайшие сроки, поможем
              решить все имеющиеся у Вас вопросы!
            </div>
            <Input
              title="Имя"
              inputs={[
                {
                  placeholder: 'Василий Петрович',
                  value: clientName,
                  type: 'text'
                }
              ]}
              isWrappedStyle
              ref={clientNameId}
              onChange={(v) => setClientName(v[0])}
            />
            <Input
              title="Телефон"
              inputs={[
                {
                  placeholder: locationState.phone,
                  value: clientPhone,
                  mask: 'phone',
                  type: 'tel'
                }
              ]}
              isWrappedStyle
              ref={clientPhoneId}
              onChange={(v) => setClientPhone(v[0])}
            />
            <Textarea
              title="Коммментарий"
              placeholder="Например, «Хочу получить консультацию перед продажей своей квартиры в городе»"
              autoHeight
              isWrappedStyle
              onChange={(v) => setClientComment(v)}
            />
            <div style={{ textAlign: 'center', opacity: 0.8, fontSize: 14, margin: '18px 0' }}>
              Вы соглашаетесь с{' '}
              <a href="/downloads/personal.pdf" target="_blank">
                политикой конфиденциальности
              </a>{' '}
              при отправке данной формы к нам в работу
            </div>
          </Fragment>
        )}
      </Modal>
      <section className="block fill">
        <div className={['fixed', styles.splitter].join(' ')}>
          <div className={styles.text}>
            <h2 className={styles.title}>{title}</h2>
            <div className={styles.subtitle}>{subtitle}</div>
          </div>
          <div className={styles.actions}>
            <div className={styles.input} ref={inputParentId}>
              <PatternFormat
                className={styles.inputZone}
                format="+# ### ### ## ##"
                type="tel"
                placeholder={locationState.phone}
                getInputRef={inputId}
                onChange={(e) => setClientPhone(e.target.value.replace(/[^0-9.]/g, ''))}
              />
              <button
                className={styles.inputButton}
                type="button"
                aria-label="Отправить"
                onClick={handleFeedbackClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="22" y1="2" x2="11" y2="13" />
                  <polygon points="22 2 15 22 11 13 2 9 22 2" />
                </svg>
              </button>
            </div>
            <div className={styles.help}>Введите свой номер телефона в поле выше</div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

Splitter.defaultProps = {
  title: 'Помочь c продажей?',
  subtitle: 'Оставьте свой номер телефона и мы обязательно поможем с продажей'
};

export default Splitter;
