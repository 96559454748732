import { useEffect, useState, useRef, Suspense } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import axios from 'axios';
import { JSONToQuery } from '../../helpers';
import { isMobile } from 'react-device-detect';
import { useLocationState } from '../../context/location';
import Skelet from '../Skelet';
import HomeCarouselSkeletonItem from './HomeCarouselSkeletonItem';
import styles from '../../styles/modules/Carousel.module.scss';

const HomeCarouselItem = dynamic(() => import('./HomeCarouselItem'), { suspense: true });

const HomeCarousel = () => {
  const locationState = useLocationState();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);
  const [carouselScrollPosition, setCarouselScrollPosition] = useState(0);
  const [carouselScrollWidth, setCarouselScrollWidth] = useState(0);
  const [carouselElWidth, setCarouselElWidth] = useState(0);
  const carouselContentEl = useRef();

  useEffect(() => {
    if (!locationState.isLoaded) return;

    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.NEXT_PUBLIC_API +
            `/objects/top${JSONToQuery({
              location: locationState.name,
              length: 9
            })}`
        );

        if (!response.data || response.data.status !== 'ok') throw new Error(response.data.text);

        setData(response.data.data);
        setIsLoading(false);
        setIsError(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
      }
    };
    fetchData();

    if (carouselContentEl.current) {
      carouselContentEl.current.scrollTo({
        left: 0,
        behavior: 'auto'
      });
      setCarouselScrollPosition(0);
    }
  }, [locationState]);

  const handlePreviousClick = () => {
    if (!carouselContentEl.current) return;

    carouselContentEl.current.scrollTo({
      left: carouselContentEl.current.scrollLeft - carouselContentEl.current.offsetWidth / 2,
      behavior: 'smooth'
    });

    setCarouselScrollPosition(
      carouselContentEl.current.scrollLeft - carouselContentEl.current.offsetWidth / 2
    );
    setCarouselScrollWidth(carouselContentEl.current.scrollWidth);
    setCarouselElWidth(carouselContentEl.current.offsetWidth);
  };
  const handleNextClick = () => {
    if (!carouselContentEl.current) return;

    carouselContentEl.current.scrollTo({
      left: carouselContentEl.current.scrollLeft + carouselContentEl.current.offsetWidth / 2,
      behavior: 'smooth'
    });

    setCarouselScrollPosition(
      carouselContentEl.current.scrollLeft + carouselContentEl.current.offsetWidth / 2
    );
    setCarouselScrollWidth(carouselContentEl.current.scrollWidth);
    setCarouselElWidth(carouselContentEl.current.offsetWidth);
  };

  if (isLoading || isError || !data || !data.length) {
    return (
      <section className="block crop">
        <div className="fixed">
          <h3>
            <Skelet style={{ maxWidth: 400, width: '100%', height: 47.5 }} />
          </h3>
          <div className={styles.carousel}>
            <div className={styles.carouselContent}>
              {[...Array(6).keys()].map((value) => (
                <HomeCarouselSkeletonItem key={value} />
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="block crop">
      <div className="fixed">
        <h3>Горячие предложения</h3>
        <div className={styles.carousel}>
          {carouselScrollPosition > 0 && (
            <button
              className={[styles.carouselButton, styles.carouselButtonPrevious].join(' ')}
              onClick={handlePreviousClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="15 18 9 12 15 6" />
              </svg>
            </button>
          )}
          <div
            className={[styles.carouselContent, isMobile && styles.touch].filter(Boolean).join(' ')}
            ref={carouselContentEl}
          >
            {data.map((value, index) => (
              <Suspense fallback={<HomeCarouselSkeletonItem />} key={index}>
                <HomeCarouselItem item={value} />
              </Suspense>
            ))}
            <Link
              href="/objects/buy/flats"
              passHref
              className={styles.carouselItemMore}
              type="button"
            >
              <span>Смотреть больше вариантов</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="5" y1="12" x2="19" y2="12" />
                <polyline points="12 5 19 12 12 19" />
              </svg>
            </Link>
            <div className={styles.carouselItemDummy} />
          </div>
          {carouselScrollPosition + carouselElWidth <= carouselScrollWidth && data.length >= 4 && (
            <button
              className={[styles.carouselButton, styles.carouselButtonNext].join(' ')}
              onClick={handleNextClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="9 18 15 12 9 6" />
              </svg>
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default HomeCarousel;
