import { useState, useEffect, useRef } from 'react';
import TextTransition, { presets } from 'react-text-transition';
import Image from 'next/image';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import HomeSearch from './HomeSearch';
import HomeWidgetCommercial from './HomeWidgetCommercial';
import { randomInt } from '../../helpers';
import styles from '../../styles/modules/home/HomeWidget.module.scss';

const HomeWidget = () => {
  const { width } = useWindowDimensions();
  const [index, setIndex] = useState(0);
  const imgUrl = useRef(`/images/stock-${randomInt(9, 13)}.webp`).current;
  const titleTexts = useRef([
    'Подбор',
    'Оформление',
    'Продажа',
    'Аренда',
    'Ипотека',
    'Оценка',
    'Ремонт',
    'Строительство'
  ]).current;

  useEffect(() => {
    const intervalId = setInterval(() => setIndex((i) => i + 1), 1 * 3000);
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <section className={['block', styles.block].join(' ')}>
      <div className="fixed">
        <div className={styles.widget}>
          <div className={styles.widgetImage}>
            <Image src={imgUrl} fill priority={width > 992} alt="" />
          </div>
          <div className={styles.widgetSearch}>
            <div className={styles.widgetSearchSlogan}>
              <TextTransition springConfig={presets.gentle} inline={false}>
                {titleTexts[index % titleTexts.length]}
              </TextTransition>
            </div>
            <HomeSearch />
            <HomeWidgetCommercial text="Квартиры без комиссии агентства" />
          </div>
          <div className={styles.widgetSearchPattern}>
            <img src="/images/pattern.svg" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeWidget;
