import Link from 'next/link';
import { useObjectsDispatch } from '../../context/objects';
import styles from '../../styles/modules/home/HomeWidgetCommercial.module.scss';

const HomeWidgetCommercial = ({ text }) => {
  const setObjectsState = useObjectsDispatch();

  const handleOnClick = () => {
    setObjectsState({
      type: 'SET_FILTER',
      payload: {
        type: 'продажа',
        category: 'квартира',
        noCommission: 1
      }
    });
  };

  return (
    <Link
      href="/objects/buy/flats?noCommission=1"
      className={styles.commercial}
      onClick={handleOnClick}
    >
      {text}
      <svg
        className={styles.commercialIcon}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="7" y1="17" x2="17" y2="7" />
        <polyline points="7 7 17 7 17 17" />
      </svg>
    </Link>
  );
};

HomeWidgetCommercial.defaultProps = {
  title: 'Стандартный заголовок',
  text: 'Стандартный текст'
};

export default HomeWidgetCommercial;
